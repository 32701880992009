import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import VueAnime from 'vue-animejs';
import VueObserveVisibility from 'vue-observe-visibility';
import filters from './filters';
import store from './store';
import routes from '@routes/routes';
import { sync } from 'vuex-router-sync';
import PhotoInspirationApp from '@views/Default.vue';
import Mobile from '@mobile/App';
import '@style/feed.mobile.scss';
import globals from '@mixins/globals.js';
import eventListenerMixin from '@mixins/eventListenerMixin.js';

Vue.config.devtools = true;

/* eslint-disable no-new */

Vue.use(VueAnime);
Vue.use(VueObserveVisibility);

for (let filterName in filters) {
  Vue.filter(filterName, filters[filterName]);
}

// load routes based on app version: "desktop" or "mobile" (default is mobile);
let router = routes(Mobile);

const $unsync = sync(store, router);
Vue.mixin({
  methods: {
    // During app/Vue teardown (e.g., you only use Vue.js in a portion of your app and you
    // navigate away from that portion and want to release/destroy Vue components/resources)
    $unsync,
  },
});

Vue.mixin(globals);
Vue.mixin(eventListenerMixin);

const renderApp = async () => {
  window.vm = new Vue({
    router,
    store,
    el: '#app',
    render: h => h(PhotoInspirationApp),
  });
};

renderApp();
