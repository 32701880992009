<template>
  <div id="app" class="container">
    <div
      v-cloak
      class="banner--inspiration"
      :class="[isSelected ? 'selected' : 'static', sticky ? 'stuck' : '']"
    >
      <template v-if="displayStaticTitle">
        <h1 class="a-StaticTitle">
          Photo Inspiration
        </h1>
      </template>
      <div v-if="!isSearch && facets.length < 1" class="feedSearch">
        <input
          v-model="searchTerm"
          class="input input--search"
          type="text"
          placeholder="Search HGTV Photo Inspiration"
          aria-label="input to search HGTV Photo Inspiration"
          @keyup.enter="search"
        />
        <span class="icon icon--inlineSearch" aria-hidden="true">
          <svg
            v-if="searchTerm == ''"
            class="icon-search"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="14"
            height="15"
            viewBox="0 0 14 15"
          >
            <path
              stroke="#C2C3C4"
              stroke-width="2"
              d="M9.8503221,10.6738515 L9.58623458,10.409764 L10.202427,9.70567998 C11.034395,8.75504275 11.5,7.53994282 11.5,6.25 C11.5,3.35050506 9.14949494,1 6.25,1 C3.35050506,1 1,3.35050506 1,6.25 C1,9.14949494 3.35050506,11.5 6.25,11.5 C7.19647716,11.5 8.10404274,11.2500948 8.90140371,10.7824532 L9.56798354,10.391513 L9.8503221,10.6738515 Z M6.25,11.5 C3.35050506,11.5 1,9.14949494 1,6.25 C1,3.35050506 3.35050506,1 6.25,1 C9.14949494,1 11.5,3.35050506 11.5,6.25 C11.5,9.14949494 9.14949494,11.5 6.25,11.5 Z"
            />
            <path
              id="path-1"
              d="M10.9549407,10.3642565 L13.4718127,12.8811285 C13.862337,13.2716528 13.862337,13.9048178 13.4718127,14.2953421 C13.0812884,14.6858664 12.4481234,14.6858664 12.0575991,14.2953421 L9.40730284,11.6450458 C8.48074857,12.1884576 7.4017566,12.5 6.25,12.5 C2.79822031,12.5 0,9.70177969 0,6.25 C0,2.79822031 2.79822031,0 6.25,0 C9.70177969,0 12.5,2.79822031 12.5,6.25 C12.5,7.82557415 11.9169934,9.26497982 10.9549407,10.3642565 Z M6.25,10.5 C8.59721019,10.5 10.5,8.59721019 10.5,6.25 C10.5,3.90278981 8.59721019,2 6.25,2 C3.90278981,2 2,3.90278981 2,6.25 C2,8.59721019 3.90278981,10.5 6.25,10.5 Z"
            />
          </svg>
        </span>
        <a v-if="searchTerm !== ''" @click="clearSearch()">
          <span class="icon icon--remove">
            <svg
              class="icon-remove"
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="7"
              viewBox="0 0 7 7"
            >
              <path
                fill="#202429"
                d="M4.64224942,3.5 L6.7634324,5.62118298 C7.07885587,5.93660645 7.07885587,6.44800893 6.7634324,6.7634324 C6.44800893,7.07885587 5.93660645,7.07885587 5.62118298,6.7634324 L3.5,4.64224942 L1.37881702,6.7634324 C1.06339355,7.07885587 0.551991066,7.07885587 0.2365676,6.7634324 C-0.0788558666,6.44800893 -0.0788558666,5.93660645 0.2365676,5.62118298 L2.35775058,3.5 L0.2365676,1.37881702 C-0.0788558666,1.06339355 -0.0788558666,0.551991066 0.2365676,0.2365676 C0.551991066,-0.0788558666 1.06339355,-0.0788558666 1.37881702,0.2365676 L3.5,2.35775058 L5.62118298,0.2365676 C5.93660645,-0.0788558666 6.44800893,-0.0788558666 6.7634324,0.2365676 C7.07885587,0.551991066 7.07885587,1.06339355 6.7634324,1.37881702 L4.64224942,3.5 Z"
              />
            </svg>
          </span>
        </a>
      </div>
      <nav
        class="navbar"
        :class="[facets.length < 1 ? 'has-shadow' : '']"
        role="navigation"
        aria-label="main navigation"
      >
        <template v-if="displayContextualTitle">
          <a
            class="a-Breadcrumb"
            :href="itemUrl()"
            @click="
              clearSearch();
              setSearch();
              clearFacets();
              setLocation();
              resetNavScroll();
            "
            >Photo Inspiration</a
          >
          <h1 class="a-ContextualTitle">
            {{ contextualTitle }}
          </h1>
        </template>
        <div
          v-if="location != 'home' && (direction == 'up' || !sticky)"
          class="navbar-home"
          :class="anchorFilterOptions.title"
        >
          <anchor-filter
            :options="anchorFilterOptions"
            @click="resetFilters"
            @update="handleAnchorUpdate"
          />
        </div>
        <div
          v-if="(direction == 'up' || !sticky) && !searchVisible && !isSearch"
          class="navbar-brand has-shadow"
          :style="{ paddingLeft: navbarBrandPaddingLeft + 'px' }"
        >
          <div v-if="location == 'home'" class="buttons buttons-home">
            <a
              v-if="sticky"
              class="button navbar-item is-flat is-search"
              @click.stop.capture="
                showSearch(true);
                setFocus();
              "
            >
              <svg
                class="icon-search"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="18"
                height="18"
                viewBox="0 0 14 15"
              >
                <path
                  stroke="#C2C3C4"
                  stroke-width="2"
                  d="M9.8503221,10.6738515 L9.58623458,10.409764 L10.202427,9.70567998 C11.034395,8.75504275 11.5,7.53994282 11.5,6.25 C11.5,3.35050506 9.14949494,1 6.25,1 C3.35050506,1 1,3.35050506 1,6.25 C1,9.14949494 3.35050506,11.5 6.25,11.5 C7.19647716,11.5 8.10404274,11.2500948 8.90140371,10.7824532 L9.56798354,10.391513 L9.8503221,10.6738515 Z M6.25,11.5 C3.35050506,11.5 1,9.14949494 1,6.25 C1,3.35050506 3.35050506,1 6.25,1 C9.14949494,1 11.5,3.35050506 11.5,6.25 C11.5,9.14949494 9.14949494,11.5 6.25,11.5 Z"
                />
                <path
                  id="path-1"
                  d="M10.9549407,10.3642565 L13.4718127,12.8811285 C13.862337,13.2716528 13.862337,13.9048178 13.4718127,14.2953421 C13.0812884,14.6858664 12.4481234,14.6858664 12.0575991,14.2953421 L9.40730284,11.6450458 C8.48074857,12.1884576 7.4017566,12.5 6.25,12.5 C2.79822031,12.5 0,9.70177969 0,6.25 C0,2.79822031 2.79822031,0 6.25,0 C9.70177969,0 12.5,2.79822031 12.5,6.25 C12.5,7.82557415 11.9169934,9.26497982 10.9549407,10.3642565 Z M6.25,10.5 C8.59721019,10.5 10.5,8.59721019 10.5,6.25 C10.5,3.90278981 8.59721019,2 6.25,2 C3.90278981,2 2,3.90278981 2,6.25 C2,8.59721019 3.90278981,10.5 6.25,10.5 Z"
                />
              </svg>
            </a>
            <!--<button-facet
                  type="shop"
                  :is-active="selectedCategories.includes('shop')"
                  @toggleOn="shoppableClick"
                  @toggleOff="removeSelectedFacet('CUSTOM_FACET:SHOPPABLE')"
                >
                  Shop The Look
                </button-facet>-->

            <button-facet
              type="rooms"
              :class="getFacetStyles('rooms')"
              @click="
                categoryClick({
                  title: 'rooms',
                  position: 1,
                  location: 'rooms',
                })
              "
            >
              Rooms
            </button-facet>

            <button-facet
              type="styles"
              :class="getFacetStyles('style')"
              @click="
                categoryClick({
                  title: 'style',
                  position: 2,
                  location: 'styles',
                })
              "
            >
              Styles
            </button-facet>

            <button-facet
              type="colors"
              :class="getFacetStyles('color')"
              @click="
                categoryClick({
                  title: 'color',
                  position: 3,
                  location: 'colors',
                })
              "
            >
              Colors
            </button-facet>
          </div>
          <div v-if="availableFilters" class="buttons">
            <template v-if="location === 'rooms'">
              <template v-if="children.length < 1">
                <button-facet
                  v-for="(item, index) in roomsParents"
                  :key="index"
                  type="rooms"
                  :is-active="isRoomsCategoryActive(item)"
                  :is-disabled="item.PageCount < 1"
                  @click="filterItemClick({ item, position: index })"
                >
                  {{ item.Id | last | onlyChild | pictureDay }}
                </button-facet>
              </template>

              <template v-for="(item, index) in filterItems">
                <button-filter
                  v-if="children.length > 0 && item.parent"
                  :key="index"
                  type="rooms"
                  :is-active="isRoomsItemActive(item)"
                  :is-disabled="item.PageCount < 1"
                  @toggleOn="filterChildClick({ item, position: index })"
                  @toggleOff="removeSelectedFacet(item.Value)"
                >
                  {{ item.Id | last | onlyChild | pictureDay }}
                </button-filter>
              </template>
            </template>

            <template v-else>
              <template v-if="children.length < 1">
                <button-filter
                  v-for="(item, index) in filterItems"
                  :key="index"
                  :type="selectedCategory.split('-')[0]"
                  :title="item.Id | last | onlyChild | pictureDay"
                  :is-active="item.isActive"
                  :is-disabled="item.PageCount < 1"
                  @toggleOn="filterItemClick({ item, position: index })"
                  @toggleOff="removeSelectedFacet(item.Value)"
                >
                  {{ item.Id | last | onlyChild | pictureDay }}
                </button-filter>
              </template>
            </template>
          </div>
        </div>
        <div
          v-if="(direction == 'up' || !sticky) && isSearch"
          class="navbar-brand navbar-brand--search has-shadow"
        >
          <div class="buttons active-search">
            <a class="button navbar-item is-search-icon">
              <svg
                class="icon-search"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="18"
                height="18"
                viewBox="0 0 14 15"
              >
                <path
                  stroke="#C2C3C4"
                  stroke-width="2"
                  d="M9.8503221,10.6738515 L9.58623458,10.409764 L10.202427,9.70567998 C11.034395,8.75504275 11.5,7.53994282 11.5,6.25 C11.5,3.35050506 9.14949494,1 6.25,1 C3.35050506,1 1,3.35050506 1,6.25 C1,9.14949494 3.35050506,11.5 6.25,11.5 C7.19647716,11.5 8.10404274,11.2500948 8.90140371,10.7824532 L9.56798354,10.391513 L9.8503221,10.6738515 Z M6.25,11.5 C3.35050506,11.5 1,9.14949494 1,6.25 C1,3.35050506 3.35050506,1 6.25,1 C9.14949494,1 11.5,3.35050506 11.5,6.25 C11.5,9.14949494 9.14949494,11.5 6.25,11.5 Z"
                />
                <path
                  id="path-1"
                  d="M10.9549407,10.3642565 L13.4718127,12.8811285 C13.862337,13.2716528 13.862337,13.9048178 13.4718127,14.2953421 C13.0812884,14.6858664 12.4481234,14.6858664 12.0575991,14.2953421 L9.40730284,11.6450458 C8.48074857,12.1884576 7.4017566,12.5 6.25,12.5 C2.79822031,12.5 0,9.70177969 0,6.25 C0,2.79822031 2.79822031,0 6.25,0 C9.70177969,0 12.5,2.79822031 12.5,6.25 C12.5,7.82557415 11.9169934,9.26497982 10.9549407,10.3642565 Z M6.25,10.5 C8.59721019,10.5 10.5,8.59721019 10.5,6.25 C10.5,3.90278981 8.59721019,2 6.25,2 C3.90278981,2 2,3.90278981 2,6.25 C2,8.59721019 3.90278981,10.5 6.25,10.5 Z"
                />
              </svg>
            </a>
            <a class="button navbar-item is-flat is-search is-inline">
              <input
                v-model="searchTerm"
                class="input"
                type="text"
                @keyup.enter="search({ resetPage: true })"
              />
            </a>
            <!--<a
                  class="button navbar-item is-flat"
                  :class="[
                    selectedCategories.includes('shop')
                      ? 'is-accent--shop'
                      : '',
                  ]"
                  @click.stop.capture="
                    trackModule({ title: 'shop', position: 0 });
                    pushShoppable();
                    resetNavScroll();
                  "
                  >Shop The Look</a
                >-->
          </div>
        </div>
        <div
          v-if="direction == 'up' && sticky && searchVisible"
          class="navbar-brand has-shadow has-search"
        >
          <a class="navbar-item" @click="showSearch()">
            <span class="icon">
              <svg
                class="icon-backArrow"
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="15"
                viewBox="0 0 9 15"
              >
                <polygon
                  fill="#202429"
                  points="3.299 7.375 8.93 1.555 7.375 0 0 7.375 7.375 14.751 8.93 13.196 4.209 8.475"
                />
              </svg>
            </span>
          </a>
          <div class="navbar-item">
            <p class="control has-icons-left has-icons-right">
              <input
                ref="search"
                v-model="searchTerm"
                class="input"
                type="text"
                @keyup.enter="search({ resetPage: true })"
              />
            </p>
          </div>
          <a
            v-if="searchTerm !== ''"
            class="navbar-item"
            @click="clearSearch()"
          >
            <span class="icon">
              <svg
                class="icon-remove"
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="7"
                viewBox="0 0 7 7"
              >
                <path
                  fill="#202429"
                  d="M4.64224942,3.5 L6.7634324,5.62118298 C7.07885587,5.93660645 7.07885587,6.44800893 6.7634324,6.7634324 C6.44800893,7.07885587 5.93660645,7.07885587 5.62118298,6.7634324 L3.5,4.64224942 L1.37881702,6.7634324 C1.06339355,7.07885587 0.551991066,7.07885587 0.2365676,6.7634324 C-0.0788558666,6.44800893 -0.0788558666,5.93660645 0.2365676,5.62118298 L2.35775058,3.5 L0.2365676,1.37881702 C-0.0788558666,1.06339355 -0.0788558666,0.551991066 0.2365676,0.2365676 C0.551991066,-0.0788558666 1.06339355,-0.0788558666 1.37881702,0.2365676 L3.5,2.35775058 L5.62118298,0.2365676 C5.93660645,-0.0788558666 6.44800893,-0.0788558666 6.7634324,0.2365676 C7.07885587,0.551991066 7.07885587,1.06339355 6.7634324,1.37881702 L4.64224942,3.5 Z"
                />
              </svg>
            </span>
          </a>
        </div>

        <div
          v-if="facets.length > 0 && !isSearch"
          class="navbar-brand selected-facets"
        >
          <a
            class="navbar-item clear-all"
            @click="
              setSearch();
              clearFacets();
              setLocation();
              resetNavScroll();
            "
            >Clear All</a
          >
          <a
            v-for="(facet, index) in facets"
            :key="index"
            class="navbar-item"
            :class="[getFacetCategory(facet)]"
            @click.prevent="removeSelectedFacet(facet)"
          >
            {{ facet | last | onlyChild | pictureDay }}
            <span class="icon is-small">
              <svg
                class="icon-remove"
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="7"
                viewBox="0 0 7 7"
              >
                <path
                  fill="#202429"
                  d="M4.64224942,3.5 L6.7634324,5.62118298 C7.07885587,5.93660645 7.07885587,6.44800893 6.7634324,6.7634324 C6.44800893,7.07885587 5.93660645,7.07885587 5.62118298,6.7634324 L3.5,4.64224942 L1.37881702,6.7634324 C1.06339355,7.07885587 0.551991066,7.07885587 0.2365676,6.7634324 C-0.0788558666,6.44800893 -0.0788558666,5.93660645 0.2365676,5.62118298 L2.35775058,3.5 L0.2365676,1.37881702 C-0.0788558666,1.06339355 -0.0788558666,0.551991066 0.2365676,0.2365676 C0.551991066,-0.0788558666 1.06339355,-0.0788558666 1.37881702,0.2365676 L3.5,2.35775058 L5.62118298,0.2365676 C5.93660645,-0.0788558666 6.44800893,-0.0788558666 6.7634324,0.2365676 C7.07885587,0.551991066 7.07885587,1.06339355 6.7634324,1.37881702 L4.64224942,3.5 Z"
                />
              </svg>
            </span>
          </a>
        </div>
        <div v-if="isSearch" class="navbar-brand selected-facets">
          <a
            class="navbar-item clear-all"
            @click="
              clearSearch();
              setSearch();
              clearFacets();
              setLocation();
              resetNavScroll();
            "
            >Clear All</a
          >
          <a
            class="navbar-item"
            @click.prevent="
              clearSearch();
              setSearch();
              clearFacets();
              setLocation();
              resetNavScroll();
            "
          >
            {{ selectedSearchTerm | pictureDay }}
            <span class="icon is-small">
              <svg
                class="icon-remove"
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="7"
                viewBox="0 0 7 7"
              >
                <path
                  fill="#202429"
                  d="M4.64224942,3.5 L6.7634324,5.62118298 C7.07885587,5.93660645 7.07885587,6.44800893 6.7634324,6.7634324 C6.44800893,7.07885587 5.93660645,7.07885587 5.62118298,6.7634324 L3.5,4.64224942 L1.37881702,6.7634324 C1.06339355,7.07885587 0.551991066,7.07885587 0.2365676,6.7634324 C-0.0788558666,6.44800893 -0.0788558666,5.93660645 0.2365676,5.62118298 L2.35775058,3.5 L0.2365676,1.37881702 C-0.0788558666,1.06339355 -0.0788558666,0.551991066 0.2365676,0.2365676 C0.551991066,-0.0788558666 1.06339355,-0.0788558666 1.37881702,0.2365676 L3.5,2.35775058 L5.62118298,0.2365676 C5.93660645,-0.0788558666 6.44800893,-0.0788558666 6.7634324,0.2365676 C7.07885587,0.551991066 7.07885587,1.06339355 6.7634324,1.37881702 L4.64224942,3.5 Z"
                />
              </svg>
            </span>
          </a>
          <a
            v-for="(facet, index) in facets"
            :key="index"
            class="navbar-item"
            :class="[getFacetCategory(facet)]"
            @click.prevent="removeSelectedFacet(facet)"
          >
            {{ facet | last | onlyChild | pictureDay }}
            <span class="icon is-small">
              <svg
                class="icon-remove"
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="7"
                viewBox="0 0 7 7"
              >
                <path
                  fill="#202429"
                  d="M4.64224942,3.5 L6.7634324,5.62118298 C7.07885587,5.93660645 7.07885587,6.44800893 6.7634324,6.7634324 C6.44800893,7.07885587 5.93660645,7.07885587 5.62118298,6.7634324 L3.5,4.64224942 L1.37881702,6.7634324 C1.06339355,7.07885587 0.551991066,7.07885587 0.2365676,6.7634324 C-0.0788558666,6.44800893 -0.0788558666,5.93660645 0.2365676,5.62118298 L2.35775058,3.5 L0.2365676,1.37881702 C-0.0788558666,1.06339355 -0.0788558666,0.551991066 0.2365676,0.2365676 C0.551991066,-0.0788558666 1.06339355,-0.0788558666 1.37881702,0.2365676 L3.5,2.35775058 L5.62118298,0.2365676 C5.93660645,-0.0788558666 6.44800893,-0.0788558666 6.7634324,0.2365676 C7.07885587,0.551991066 7.07885587,1.06339355 6.7634324,1.37881702 L4.64224942,3.5 Z"
                />
              </svg>
            </span>
          </a>
        </div>
      </nav>
    </div>
    <section class="o-PromoSection">
      <facetPromo
        v-if="displayPromoFeed"
        :show-promo="facetPromoEnabled"
        path="src/components/common/facet-promo/index.mobile.vue"
        @update-facets="setRawFacets($event)"
        @update-search-term="updateSearchTerm($event)"
      />

      <browseFilters
        v-if="displayPromoFeed"
        path="src/components/common/browse-filters/index.mobile.vue"
        @update-search-term="updateSearchTerm($event)"
        @browse-filter="applyBrowseFilter($event)"
      />
    </section>

    <section class="o-FeedSection" :class="{ isLoading: isLoading }">
      <loader :is-loading="isLoading" />
      <h3 v-if="displayPromoFeed && !noResults" class="o-Gallery__a-Headline">
        Recent Photos
      </h3>
      <div class="o-FeedSection--photos">
        <template v-if="!noResults">
          <div
            v-for="(image, index) in images"
            v-observe-visibility="{
              callback: visibilityChanged,
            }"
            :key="index"
            v-cloak
            class="card"
            :class="[
              image.shoppable || image.type === 'gallery' ? 'card--full' : '',
              image.shoppable ? 'is-shoppable' : '',
              image.type === 'gallery' ? 'card--gallery title--bottom' : '',
            ]"
            :data-product-id="[image.shoppable ? image.productId : 0]"
            data-track="true"
            data-row="true"
            :data-index="index"
            :data-rowIndex="index"
            :data-term="image.route.term"
            :data-dimensions="image.route.dimensions"
            :data-page="image.route.page"
            :data-image-id="image.id"
          >
            <div class="card-image" :class="'card-image--' + ((index % 6) + 1)">
              <figure class="image is-square">
                <a
                  :href="image.url"
                  target="_blank"
                  @click.stop.prevent.capture="trackAndGoToImage(image, index)"
                >
                  <img
                    :data-src="image.image"
                    :alt="image.title"
                    :data-track="image.track"
                    :data-mdm="image.mdm"
                    :data-product-id="image.productId"
                    :data-image-id="image.id"
                  />
                </a>
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <a
                    :href="image.url"
                    target="_blank"
                    @click.stop.capture="
                      trackModule({
                        name: 'inspirationFeed',
                        title: image.title,
                        position: index,
                        target: image.url,
                      })
                    "
                    ><p class="title is-6">{{ image.title }}</p></a
                  >
                </div>
              </div>

              <div
                v-if="image.shoppable && getProduct(image.productId).length > 0"
                class="o-PhotoGalleryPromo__m-ProductListWrap m-ProductListWrap"
              >
                <div class="o-PhotoGalleryPromo__m-ProductListContent">
                  <div
                    class="o-PhotoGalleryPromo__m-ProductsList has-Products is-Loaded is-Sponsored"
                  >
                    <div class="m-ProductsList__m-Partner">
                      <div class="m-Partner__a-By">
                        <svg
                          v-if="image.shoppable"
                          class="icon-product"
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 12 13"
                        >
                          <path
                            fill="#202429"
                            d="M11.5,0.6h-5c-0.1,0-0.2,0-0.3,0.1l-2,2L3.5,3.4L0.2,6.7C0,6.9,0,7.1,0.2,7.3l5,5c0.1,0.1,0.4,0.1,0.5,0L9,8.9l0.8-0.7l2-2c0.1-0.1,0.1-0.2,0.1-0.3l0-5C11.8,0.7,11.7,0.6,11.5,0.6z M9.3,3.9c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8S9.8,3.9,9.3,3.9z"
                          />
                        </svg>
                        Shop This Look
                      </div>
                      <a
                        href="https://wayfair.com/?refid=HGTV12"
                        target="_blank"
                        >Wayfair.com</a
                      >
                    </div>
                    <div class="m-ProductsList__m-Body">
                      <div class="l-Columns">
                        <div
                          v-for="(product, index) in getProduct(
                            image.productId,
                          )"
                          :key="index"
                          class="m-ProductsList__m-ProductSmall m-ProductSmall"
                        >
                          <a
                            class="m-ProductSmall__m-Link"
                            rel="nofollow noskim"
                            :href="
                              product.product_url +
                                '?refid=HGTV12&amp;ss_hgtv_product=1'
                            "
                            target="_blank"
                            :title="product.name"
                            @click.stop.capture="trackProductLink(product)"
                          >
                            <div class="m-ProductSmall__m-MediaWrap">
                              <div class="m-ProductSmall__m-ImageWrap">
                                <img
                                  data-sizes="auto"
                                  :src="
                                    product.both_dimensions
                                      ? product.both_dimensions
                                      : product.image_url
                                  "
                                  :alt="product.name"
                                  sizes="70px"
                                />
                              </div>
                            </div>
                            <div class="m-ProductSmall__m-TextWrap">
                              <h4 class="m-ProductSmall__a-Headline">
                                <span class="m-ProductSmall__a-HeadlineText">{{
                                  product.name
                                }}</span>
                              </h4>
                              <div class="m-ProductSmall__a-Price">
                                ${{
                                  product.sale_price
                                    ? product.sale_price.toFixed(2)
                                    : product.unit_list_price.toFixed(2)
                                }}
                              </div>
                              <div class="m-ProductSmall__a-ShippingInfo">
                                {{
                                  product.has_free_shipping
                                    ? 'Free Delivery'
                                    : ''
                                }}
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="m-ProductsList__a-LegalText a-LegalText">
                      We may make &#x1f4b0; from these links.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <paginate
            :hide-pagination="this.hidePagination"
            :page-count="this.totalPages"
            :click-handler="toPage"
            :value="this.pageNum"
            :hide-prev-next="true"
            :hide-numbers="true"
            class="pagination"
            :container-class="'pagination-wrapper'"
            :page-url="this.pageUrl"
            :hash-props="this.hashProps"
            :is-mobile-device="true"
          />
        </template>
        <template v-else>
          <no-results :query="searchTerm" />
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import appMixin from '@mixins/app';
import facetPromo from '@common/facet-promo';
import browseFilters from '@common/browse-filters';
import filterBar from '@mixins/filter-bar';

import buttonFacet from '@common/buttons/button-facet';
import buttonFilter from '@common/buttons/button-filter';
import paginate from '@common/paginate';

const lightboxPattern = /(lightbox\/)(.*)$/g;

export default {
  name: 'Mobile',
  components: {
    buttonFacet,
    buttonFilter,
    facetPromo,
    browseFilters,
    paginate,
  },

  mixins: [appMixin, filterBar],
  props: {
    term: {
      type: String,
      default: null,
    },
    dimensions: {
      type: String,
      default: '',
    },
    lightboxId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      facetPromoEnabled: true,
      feedShown: false,
    };
  },

  computed: {
    displayContextualTitle() {
      return !this.sticky && this.contextualTitle;
    },
    isSelected() {
      return this.facets.length > 0 || this.isSearch;
    },
    displayStaticTitle() {
      return !this.isSelected && !this.sticky && !this.contextualTitle;
    },
    displayPromoFeed() {
      return (
        this.facetPromoEnabled && !this.isSelected && !this.contextualTitle
      );
    },
  },

  methods: {
    toPage(num) {
      let vm = this;
      vm.bottom = false;
      this.pageNum = parseInt(num);
      this.offset =
        this.pageNum <= 1
          ? 0
          : (this.pageNum - 1) * parseInt(this.numOfResults);
      this.clearImages();
      this.setRouteInfo();
      window.scrollTo(0, 0);
      this.addImages();
    },
    setRawFacets(facetArray) {
      if (facetArray.length) {
        this.facets = facetArray;
      }
    },
    setThreshold() {
      let offsetHeight = 0;
      const els = {
        header: document.querySelector('.header'),
        banner: this.$el.querySelector('.banner--inspiration'),
      };

      for (let el in els) {
        if (els[el]) offsetHeight += els[el].clientHeight;
      }

      this.threshold = offsetHeight;
    },
    visibilityChanged(isVisible, entry) {
      // In Mobile, visibility is essential to track routing... is there a better way?
      if (isVisible) {
        const dataset = entry.target.dataset;
        const rowIndex = parseInt(dataset.index);
        if (rowIndex >= 0) {
          this.updateRoute({
            ...dataset,
            rowIndex,
          });
        }
      }
      if (isVisible || !this.isLoading) {
        // Initial visibility will cause a scroll if there is a lightbox image available (appReadyForScroll)
        // We don't want to change that again, hence the FeedShown flag will prevent further changes
        if (this.feedShown) {
          return;
        } else {
          this.appReadyForScroll = true;
          this.feedShown = true;
        }
      }
    },
    setLightboxId(image) {
      let path;
      if (image) {
        if (this.route.params.lightboxId) {
          path = this.route.path.replace(lightboxPattern, '$1' + image.id);
        } else {
          path = `${this.route.path}lightbox/${image.id}`;
        }
      } else {
        path = this.route.path.replace(lightboxPattern, '');
      }
      this.$router.push({ path });
    },
    trackAndGoToImage(image, index) {
      if (image && index >= 0) {
        this.trackModule({
          name: 'inspirationFeed',
          title: image.title,
          position: index,
          target: image.url,
        });
        // Update the router to have a lightbox id before exiting
        this.setLightboxId(image);
        window.location.href = image.url;
      }
    },
    itemUrl() {
      return window.location.protocol + '//' + window.location.host;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/variables/index.scss';

svg {
  &.icon-product {
    margin-right: 2px;
  }

  &.icon-remove {
    margin: 2px 0 1px 4px;
  }
}

.is-small {
  svg.icon-backArrow {
    margin: 0;
  }
}

.is-search {
  svg.icon-search {
    margin: 0 8px 0 8px;
  }
}

.navbar {
  .anchor-filter {
    margin-right: 0;
  }
}

.navbar-home {
  display: flex;
  align-items: center;
  z-index: 1;
  border: 0;
  padding: 11px 0 4px 8px;
  &.style,
  &.color {
    padding-top: 9px;
  }
  .anchor-filter {
    height: 34px;
  }
}

.buttons-home {
  margin: 0 auto;
}

.a-ContextualTitle,
.a-StaticTitle {
  margin: 0;
  padding: 0.5rem 0 0.25rem 8px;
  font-size: 26px;
}

.a-StaticTitle {
  position: absolute;
  top: 0.6rem;
}

.a-ContextualTitle {
  & + .navbar-brand--search {
    margin-top: 0.5rem;
  }
}

.a-StickyBanner {
  position: absolute;
}

.a-Breadcrumb {
  display: inline-block;
  padding: 0;
  margin-left: 0.5rem;
  margin-top: 1rem;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #4a4a4a;
  text-transform: uppercase;
}

.title {
  font-weight: inherit;
}

.has-search {
  p.control {
    margin: 0;
    padding: 0;
  }
}
.o-Gallery__a-Headline {
  @media only screen and (max-width: 993px) {
    width: 100%;
    font-size: 23px;
    font-weight: 300;
    margin: 30px 16px 20px;
  }
}

.o-FeedSection {
  position: relative;
  &.isLoading {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 29;
      background: #fff;
      opacity: 0.9;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 40px 10px 0;
  width: 100%;
  li {
    margin: 0 12px;
    padding: 12px;
    min-width: 6px;
    height: 19px;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      color: #000000;
    }
    &.active a,
    &.disabled a {
      cursor: pointer;
    }
    &:first-child a,
    &:last-child a {
      text-transform: uppercase;
    }
  }
  .pagination-wrapper {
    display: flex;
  }
}
</style>
